import * as React from 'react';

import { Button, Container, Row, Spacer } from '@nextui-org/react';
import type { NextPageContext } from 'next';
import { getSession, signIn } from 'next-auth/react';
import { ROUTES } from '@src/config/constants';

interface SignInPageProps {
  showGitHubLogin: boolean;
  showGoogleLogin: boolean;
  showTestLogin: boolean;
}

/**
 * @name getServerSideProps
 * @description Get the props for the page or redirect to login.
 */
export async function getServerSideProps(context: NextPageContext) {
  const session = await getSession(context);
  if (session) {
    return {
      redirect: {
        destination: ROUTES.clients,
        permanent: false,
      },
    };
  }

  const showTestLogin =
    process.env.STAGE === 'local' || process.env.STAGE === 'preview';

  const showGitHubLogin =
    process.env.STAGE === 'local' || process.env.STAGE === 'staging';

  const showGoogleLogin = process.env.STAGE !== 'preview';

  return {
    props: {
      showGitHubLogin,
      showGoogleLogin,
      showTestLogin,
    },
  };
}

/**
 * @name SignInPage
 * @description The SignIn page.
 */
export default function SignInPage(props: SignInPageProps) {
  const { showGitHubLogin, showGoogleLogin, showTestLogin } = props;

  const signInTestUser = async () => {
    signIn('credentials', {
      callbackUrl: window.location.origin,
      email: 'test@user.com',
      password: 'test',
      redirect: true,
    });
  };

  const signInWithGitHub = () => {
    signIn('github', { callbackUrl: window.location.origin, redirect: true });
  };

  const signInWithGoogle = () => {
    signIn('google', { callbackUrl: window.location.origin, redirect: true });
  };

  return (
    <Container xs gap={0}>
      <Spacer />
      {showGitHubLogin && (
        <Row justify="center">
          <Button
            auto
            onClick={signInWithGitHub}
            icon={
              <div style={{ height: '24px', width: '24px' }}>
                <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            }
          >
            Sign in with GitHub
          </Button>
        </Row>
      )}
      <Spacer />
      {showGoogleLogin && (
        <Row justify="center">
          <Button
            auto
            color="success"
            onClick={signInWithGoogle}
            icon={
              <div style={{ height: '24px', width: '24px' }}>
                <svg
                  aria-hidden="true"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="24px"
                  height="24px"
                >
                  {' '}
                  <path d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z" />
                </svg>
              </div>
            }
          >
            Sign in with Google
          </Button>
        </Row>
      )}
      <Spacer />
      {showTestLogin && (
        <Row justify="center">
          <Button auto onClick={signInTestUser} color="secondary">
            Sign in Test
          </Button>
        </Row>
      )}
    </Container>
  );
}
